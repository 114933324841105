import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo";

import '../styles/styles.css';
import './info.css';

/**
 *
 */
const InfoPage = () => (
    <Layout>
        <SEO title={"Info"} />
    <div id="component" className="container">
      <h3>Wondering how the tests work?</h3>
      
      <p>The goal of partisan gerrymandering is to amplify a political party's power beyond what it deserves based on their vote share alone. This process is accomplished by two complementary methods: <a href="https://www.propublica.org/article/redistricting-a-devils-dictionary" target="_blank">packing and cracking</a>. "Packing" occurs when many supporters of the victim party are jammed into a small number of districts, giving them a few overwhelming wins. The remaining members of the victim party are then "cracked," spread across a large number of districts, so that they consistently win just under 50% of the vote. Luckily, packing and cracking creates a distinctive pattern of wins for both the perpetrator and the victim parties, wherein the victim party wins its few seats by overwhelming margins and the perpetrating party wins its many seats by considerably lower margins. This pattern, and thus partisan gerrymanders, can be detected with <a href="https://www.nytimes.com/2015/12/06/opinion/sunday/let-math-save-our-democracy.html" target="_blank">the help of a little math</a>.
      </p>
      
      <figure className="full">
      <a href="/assets/figs/packing_and_cracking.png" fadeDuration={100} imageFadeDuration={100} resizeDuration={100}>
        <img src="/assets/figs/packing_and_cracking.png" className="img-thumbnail half" />
      </a>
      <a href="/assets/figs/nc_packing_cracking.png" fadeDuration={100} imageFadeDuration={100} resizeDuration={100}>
        <img src="/assets/figs/nc_packing_cracking.png" className="img-thumbnail half"/>
      </a>
      <figcaption>Two examples of packing and cracking. The first figure illustrates how the same political geography can be exploited by either side to gain an advantage. The second figure shows how North Carolina's 2016 Congressional map packed and cracked densely-populated areas, which tend to support Democratic candidates.</figcaption>
      </figure>
    

      <h4>Lopsided Wins (t-test)</h4>
      <figure className="right" style={{width: "23em"}}>
        <a href="/assets/figs/nc_ttest.png">
          <img src="/assets/figs/nc_ttest.png" class="img-thumbnail" style={{padding: ".25em", backgroundColor: "white", width: "calc(100% - .5em)"}}></img>
        </a>
        <figcaption>
          An example of the clustering effect detectable by the t-test in North Carolina's 2012 Congressional elections.
        </figcaption>
      </figure>
      
      <p>The two-sample t-test, perhaps <a href="http://vassarstats.net/textbook/ch11pt1.html" target="_blank">the most widely used statistical test of all time</a>, tests how similar two groups of numbers are. In the case of gerrymandering, the t-test can determine how similar the winning vote shares are for the districts won by Democrats to those for districts won by Republicans. In a perfectly fair world, it could be assumed that these two groups of vote shares would be very similar. Each party might expect to see wins in a mix of strongly partisan districts, moderately reliable districts, and tossups&#8212;but each party should expect to have a roughly similar mix. In a gerrymandered state, by contrast, the victim party mostly has strong wins (in their packed districts) and the perpetrating party mostly has small but safe wins. The t-test can check for this distinctive pattern of lopsided outcomes, and can check for the probability that such a pattern might have arisen by chance. If an outcome was unlikely to have occurred by chance alone, that may indicate that the state suffered a partisan gerrymander.
      </p>
      
      <p>We use the two-tailed t-test, which can be performed in Microsoft Excel using <a href="https://support.office.com/en-us/article/T-TEST-function-d4e08ec3-c545-485f-962e-276f7cbed055">the T.Test function</a>.
      </p>
       {/*one-tailed test that tests the hypothesis that the party winning a majority of the seats in a state has lower win margins and therefore may have perpetrated a gerrymander.*/}
      

      <h4>Consistent Advantage (Mean-Median Difference)</h4>
      <p>Partisan gerrymandering can also be detected by comparing a party's statewide vote strength to the number of Congressional seats it wins. As mentioned above, in a gerrymander of a state in which the vote is roughly evenly split between parties, many of the supporters of the victim party are crammed into a few districts that they win overwhelmingly, and the remaining supporters are spread across districts that they lose by small margins. This effect can be discerned by examining the difference between the mean and median vote shares of either party. A large difference between the mean and median is unlikely to arise by chance, and therefore may be indicative of a partisan gerrymander.
      </p>

      <p>The mean-median difference is simply calculated by subtracting the median vote share of either party across all districts from the average vote share of the same party across all districts. A negative mean-median difference indicates that the examined party has an advantage; a positive difference indicates that the examined party is disadvantaged. A <a href="https://en.wikipedia.org/wiki/Standard_score" target="_blank">z-score</a> for the effect can be calculated by dividing the mean-median difference by the standard error of the party's vote share across districts, then applying a correction factor of .5708, as described in <a href="http://onlinelibrary.wiley.com/doi/10.2307/3315744/abstract" target="_blank"><em>A Simple Test of Symmetry About an Unknown Median</em></a> by Cabilio and Masaro (1996).
      </p>

      <p>In states where one party dominates at a statewide level, the mean-median difference becomes less robust. In those situations, tests of variance can be applied to detect asymmetric advantage, as described in our <a href="https://web.math.princeton.edu/~sswang/wang16_ElectionLawJournal_gerrymandering-MD-WI_.pdf" target="_blank">Election Law Journal article</a>.
      </p>

      <h4>Excess Seats (simulated elections)</h4>
      
      <figure className="right">
      <a href="/assets/figs/monte_carlo.png">
        <img src="/assets/figs/monte_carlo.png" className="img-thumbnail" />
      </a>
        <figcaption>
          The result of Monte Carlo analysis performed on Pennsylvania in 2012. None of the simulations showed as extreme a result as actually occurred (Democrats winning 51% of the vote but only 5 seats).
        </figcaption>
      </figure>
      
      <p>Another way to detect possible gerrymanders is to examine how much the makeup of a state's Congressional delegation deviates from national results, given the state's party divide. The statistical method for such analysis is <a href="https://en.wikipedia.org/wiki/Monte_Carlo_method" target="_blank">Monte Carlo simulation</a>, or bootstrapping. Essentially, this test is a Congressional version of fantasy baseball&#8212;out of the 435 districts nationally, randomly pick as many as are in the state of interest, check if they match the overall partisan makeup of the state and, if they do, record the party split of the districts' Congressional delegation. Repeat the process millions of times, then compare the results of the simulations to the actual state results.
      </p>
      
      <p>A set of districts is said to match the partisan makeup of a state if the total vote share for each party across all districts is within 0.1% of the actual vote share across all districts. We then determine the proportion of simulations that had outcomes as or more advantageous to the dominant party than occurred in reality (e.g. in states with a majority Democratic delegation, the number of simulations with as many or more Democratic seats).
      </p>
    </div>
    </Layout>
);

export default InfoPage;